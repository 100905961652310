import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial',
    h3: {
      fontSize: '1.4rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.2rem',
      fontWeight: 700,
    },
  },
});

theme.palette.success.main = '#4caf50';
export default theme;
