import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Page } from './types';

type AppState = {
  pages: Page[];
  isLoading: boolean;
  error: {
    message: string;
  };
};

const initialState: AppState = {
  pages: [],
  isLoading: false,
  error: {
    message: '',
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPages: (state, action: PayloadAction<Page[]>) => {
      state.pages = action.payload;
    },
    addPage: (state, action: PayloadAction<Page>) => {
      state.pages.push(action.payload);
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error.message = action.payload;
    },
  },
});

export const { setLoading, setPages, addPage, setError } = appSlice.actions;
export default appSlice.reducer;
