import React, { useCallback, useMemo, useState } from 'react';
import { Avatar, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { clearUser } from '../features/accessControl/authSlice';
import { useNavigate } from 'react-router-dom';
import { deepOrange } from '@mui/material/colors';
import { logout } from '../features/accessControl/api';

type AvatarButtonProps = {
  onSettingsOpen?: () => void;
};
type AvatarMenuProps = {
  anchorEl: HTMLElement | null;
  actions: MenuActions[];
  close: () => void;
};

type MenuActions = {
  label: string;
  action: () => void;
};

const AvatarMenu = ({ anchorEl, actions, close }: AvatarMenuProps) => {
  return (
    <Menu anchorEl={anchorEl} open={anchorEl != null} onClose={close}>
      {actions.map(({ label, action }, index) => (
        <MenuItem key={index} onClick={action}>
          <Box sx={{ minWidth: 120 }}>{label}</Box>
        </MenuItem>
      ))}
    </Menu>
  );
};
const AvatarButton = ({ onSettingsOpen }: AvatarButtonProps) => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const userProfile = useSelector((state: RootState) => state.auth.profile);
  const avatarRef = React.useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogout = useCallback(async () => {
    setAnchorEl(null);
    dispatch(clearUser());
    await logout();
    navigate('/login');
  }, [dispatch, navigate]);

  const actions = useMemo(() => {
    const actions = [
      {
        label: 'Logout',
        action: handleLogout,
      },
    ];
    if (onSettingsOpen) {
      actions.unshift({
        label: 'Settings...',
        action: async () => {
          setAnchorEl(null);
          onSettingsOpen();
        },
      });
    }
    return actions;
  }, [handleLogout, onSettingsOpen]);
  const onAvatarClick = useCallback(() => {
    setAnchorEl(avatarRef.current);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton size="small" ref={avatarRef} onClick={onAvatarClick}>
        <Avatar
          sx={{
            bgcolor: deepOrange[500],
          }}
        >
          {userProfile?.username?.toUpperCase().substring(0, 1) ?? ''}
        </Avatar>
      </IconButton>
      <AvatarMenu anchorEl={anchorEl} actions={actions} close={closeMenu} />
    </>
  );
};

export default AvatarButton;
