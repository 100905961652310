import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserProfile = {
  username: string;
};
type AuthState = {
  profile?: UserProfile;
  authToken?: string;
  permissions: string[];
};

export const initialState: AuthState = {
  profile: undefined,
  authToken: undefined,
  permissions: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (
      state,
      action: PayloadAction<{ authToken: string; profile?: UserProfile }>
    ) => {
      const { authToken, profile } = action.payload;
      state.profile = profile;
      state.authToken = authToken;

      // TODO: extract permissions from token.
    },
    clearUser: (state) => {
      state.profile = undefined;
      state.authToken = undefined;
      state.permissions = [];
    },
  },
});

export const { setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;
