import * as React from 'react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  Drawer,
  IconButton,
  Paper,
  Snackbar,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Navigator from './Navigator';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setError, setPages } from '../appSlice';
import Cookies from 'js-cookie';
import { login } from '../features/accessControl/api';
import { setUser } from '../features/accessControl/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import AvatarButton from './AvatarButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Page } from '../types';
import { css } from '@emotion/react';
import Settings from './Settings';

type WorkspaceProps = React.PropsWithChildren<{
  showBackLink?: boolean;
  backLinkUrl?: string;
  sideBarButton?: ReactNode;
}>;

type SettingsDialogProps = React.PropsWithChildren<{
  open: boolean;
  isMobile: boolean;
  onClose: () => void;
}>;

const SettingsDialog = ({ open, onClose, isMobile }: SettingsDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      maxWidth="md"
      scroll="paper"
      css={css({
        '.MuiDialog-container': {
          alignItems: 'flex-start',
        },
      })}
      sx={{
        height: {
          xs: '100%',
          md: 'inherit',
        },
        maxHeight: {
          xs: '100%',
          md: '80vh',
        },
      }}
    >
      <Box position="relative" height="100%">
        <Box height="100%">
          <Settings />
        </Box>
        <IconButton
          aria-label="close"
          css={css({
            position: 'absolute',
            top: 8,
            right: 4,
          })}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </Dialog>
  );
};

const Workspace = ({ showBackLink, backLinkUrl, sideBarButton, children }: WorkspaceProps) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const errorMessage = useSelector((state: RootState) => state.app.error.message);
  const isLoggedIn = useSelector((state: RootState) => state.auth.authToken != null);
  const pages = useSelector((state: RootState) => state.app.pages).filter((page) => page.nav);
  const selectedPage = useMemo(() => {
    return pages.find((page) => page.path && location.pathname.startsWith(page.path));
  }, [location.pathname, pages]);

  const [showSideNav, setShowSideNav] = useState(false);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (!isLoggedIn) {
      const authToken = Cookies.get('authToken');
      if (authToken) {
        login()
          .then((responseBody) => {
            const { token, profile, pages } = responseBody;
            dispatch(
              setUser({
                authToken: token,
                profile: { username: profile?.username },
              })
            );
            dispatch(setPages(pages));
            const hasPage = pages.some((page: Page) => page.path === location.pathname);
            if (hasPage) {
              navigate(location.pathname);
            } else {
              navigate(pages[0].path);
            }
          })
          .catch((error) => {
            navigate('/login');
          });
      } else {
        navigate('/login');
      }
    }
  }, [navigate, dispatch, location.pathname, isLoggedIn]);

  const handleBackLinkClicked = useCallback(() => {
    if (backLinkUrl) {
      navigate(backLinkUrl);
    } else {
      navigate(-1);
    }
  }, [navigate, backLinkUrl]);

  const mainStyles = isMobile
    ? {
        marginLeft: 0,
        width: '100%',
      }
    : {
        marginLeft: 240,
        width: 'calc(100% - 240px)',
      };

  const showAlert = () => {
    console.log('showAlert called');
  };

  useEffect(() => {
    console.log('workspace');
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Box
        flexGrow={1}
        flexBasis={1}
        display="flex"
        width="100%"
        height="100%"
        bgcolor={theme.palette.background.default}
      >
        <nav>
          <Drawer
            anchor="left"
            variant={isMobile ? 'temporary' : 'permanent'}
            open={showSideNav}
            onClose={() => {
              setShowSideNav(false);
            }}
          >
            <Box pt={2} height="100%" width={240} overflow="auto" bgcolor="#1e2535" position="relative">
              {isMobile && (
                <Box component="span" position="absolute" right={4} top={4}>
                  <IconButton css={{ color: '#fff' }} onClick={() => setShowSideNav(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
              <Box height={42}></Box>
              <Navigator
                pages={pages}
                selectedPage={selectedPage}
                itemStyle={css({ color: '#8b929d' })}
                selectedItemStyle={css({ color: '#fff' })}
                onPageSelection={(selectedPage: Page | undefined) => {
                  setShowSideNav(false);
                  if (selectedPage) {
                    setTimeout(
                      () => {
                        navigate(selectedPage.path ?? `/${selectedPage.name}`);
                      },
                      isMobile ? 250 : 0
                    );
                  }
                }}
              />
            </Box>
          </Drawer>
        </nav>

        <main css={mainStyles}>
          <Box display="flex" flexDirection="column" height="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {isMobile ? (
                <Toolbar variant="dense">
                  <IconButton
                    onClick={() => {
                      setShowSideNav(true);
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              ) : (
                <Box ml={1} display="flex" justifyContent="space-between">
                  {showBackLink && (
                    <Button variant="text" onClick={handleBackLinkClicked}>
                      <Box display="flex" alignItems="center">
                        <ArrowBackIcon />
                        Back
                      </Box>
                    </Button>
                  )}
                </Box>
              )}
              <Toolbar variant="dense">
                <AvatarButton onSettingsOpen={() => setShowSettingsDialog(true)} />
              </Toolbar>
            </Box>

            <Box height="calc(100% - 40px)" p={2}>
              {isMobile && (
                <Box display="flex" justifyContent="space-between">
                  {showBackLink && (
                    <Button variant="text" onClick={handleBackLinkClicked}>
                      <Box display="flex" alignItems="center">
                        <ArrowBackIcon />
                        Back
                      </Box>
                    </Button>
                  )}
                  {sideBarButton}
                </Box>
              )}
              <Box height="100%" display="flex" justifyContent="center">
                <Box maxWidth={1280} flexGrow="1" width="100%" display="flex" justifyContent="flex-start">
                  {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                      return React.cloneElement(child as React.ReactElement, { showAlert });
                    } else {
                      return child;
                    }
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
        </main>
      </Box>

      <Snackbar
        open={errorMessage.length > 0}
        autoHideDuration={6000}
        onClose={() => {
          dispatch(setError(''));
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box maxWidth={300}>
          <Paper square elevation={7}>
            <Box borderLeft={`solid 4px ${theme.palette.error.main}`} p={1.5}>
              <Typography>{errorMessage}</Typography>
            </Box>
          </Paper>
        </Box>
      </Snackbar>

      <SettingsDialog
        open={showSettingsDialog}
        isMobile={isMobile}
        onClose={() => {
          setShowSettingsDialog(false);
        }}
      />
    </Box>
  );
};

export default Workspace;
