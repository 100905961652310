import React, { useCallback } from 'react';
import { Box, List, ListItem, ListItemText } from '@mui/material';
import { css, SerializedStyles } from '@emotion/react';
import { Page } from '../types';

type NavigatorItemProps = {
  page: Page;
  handlePageSelection: (pageName: string) => void;
  itemStyle: SerializedStyles;
};

type NavigatorProps = {
  pages: Page[];
  selectedPage: Page | undefined;
  onPageSelection?: (selectedPage: Page | undefined, pages?: Page[]) => void;
  itemStyle?: SerializedStyles;
  selectedItemStyle?: SerializedStyles;
};

const NavigatorItemStyles = css({
  cursor: 'pointer',
});

const NavigatorItem = ({ page, handlePageSelection, itemStyle }: NavigatorItemProps) => {
  return (
    <Box css={itemStyle}>
      <ListItem onClick={() => handlePageSelection(page.name)} css={NavigatorItemStyles}>
        <ListItemText primary={page.title ?? page.name} />
      </ListItem>
    </Box>
  );
};

const Navigator = (props: NavigatorProps) => {
  const { pages, selectedPage, onPageSelection } = props;
  const itemStyle = props.itemStyle ?? css({ color: 'inherit' });
  const selectedItemStyle = props.selectedItemStyle ?? css({ span: { fontWeight: 'bold' } });

  const handlePageSelection = useCallback(
    () => (pageName: string) => {
      if (typeof onPageSelection === 'function') {
        const selectedPage = pages.find((page) => page.name === pageName);
        onPageSelection(selectedPage, pages);
      }
    },
    [pages, onPageSelection]
  );

  return (
    <List css={{ 'overflow-y': 'auto' }}>
      {pages.map((page) => {
        const blah = page.name === selectedPage?.name ? selectedItemStyle : itemStyle;
        return (
          <NavigatorItem key={page.name} page={page} itemStyle={blah} handlePageSelection={handlePageSelection()} />
        );
      })}
    </List>
  );
};

export default Navigator;
