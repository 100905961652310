import * as React from 'react';
import { FormEvent, useState } from 'react';
import { Box, Button, FormControl, Paper, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { login } from '../features/accessControl/api';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { setUser } from '../features/accessControl/authSlice';
import { setPages } from '../appSlice';

const buttonStyles = css({
  textTransform: 'revert',
});

const LoginForm = () => {
  const theme = useTheme();
  const dispatch: AppDispatch = useDispatch();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [usernameHelperText, setUsernameHelperText] = useState<string>('');
  const [passwordHelperText, setPasswordHelperText] = useState<string>('');
  const [loginResponse, setLoginResponse] = useState<string>('');

  const navigate = useNavigate();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    switch (event.target.name) {
      case 'username':
        setUsername(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  };

  const validate = () => {
    if (isEmpty(username)) {
      setUsernameHelperText('Please enter your username');
    }
    if (isEmpty(password)) {
      setPasswordHelperText('Please enter your password');
    }
    return !isEmpty(username) && !isEmpty(password);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const valid = validate();
    if (valid) {
      try {
        const { token, profile, pages } = await login(username, password);
        dispatch(
          setUser({
            authToken: token,
            profile: { username: profile?.username },
          })
        );
        dispatch(setPages(pages));
        setLoginResponse('');
        navigate(pages[0].path);
      } catch (error) {
        const message = (error as Error).message ?? 'Login failed';
        setLoginResponse(message);
      }
    }
  };

  return (
    <Box>
      <Box mt={2} component="form" onSubmit={handleSubmit}>
        <Box mb={1.5}>
          <FormControl fullWidth>
            <TextField
              id="username-input"
              name="username"
              label="username"
              value={username}
              onChange={handleInputChange}
              helperText={usernameHelperText}
              error={!!usernameHelperText}
            />
          </FormControl>
        </Box>
        <Box mb={1.5}>
          <FormControl fullWidth>
            <TextField
              type="password"
              id="password-input"
              name="password"
              label="password"
              value={password}
              variant="outlined"
              onChange={handleInputChange}
              helperText={passwordHelperText}
              error={!!passwordHelperText}
            />
          </FormControl>
        </Box>
        <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
          <Button variant="contained" color="primary" type="submit" css={buttonStyles}>
            Login
          </Button>
          {/*<Button size="small" variant={'text'} css={buttonStyles}>*/}
          {/*  Forgot password*/}
          {/*</Button>*/}
        </Box>
      </Box>
      <Snackbar
        open={loginResponse.length > 0}
        autoHideDuration={6000}
        onClose={() => {
          setLoginResponse('');
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box maxWidth={300}>
          <Paper square>
            <Box borderLeft={`solid 4px ${theme.palette.error.main}`} p={1.5}>
              <Typography>{loginResponse}</Typography>
            </Box>
          </Paper>
        </Box>
      </Snackbar>
    </Box>
  );
};

export default LoginForm;
