import React, { lazy, Suspense, useMemo } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Workspace from './components/Workspace';
import Login from './pages/Login';
import { useSelector } from 'react-redux';
import { RootState } from './store';

function App() {
  const pages = useSelector((state: RootState) => state.app.pages);
  const pageComponents = pages.map((page) => lazy(() => import(`./pages/${page.name}`)));

  const routes = useMemo(() => {
    const routes = pages.map((page, index) => (
      <Route key={page.path} path={page.path} element={React.createElement(pageComponents[index])} />
    ));
    return routes;
  }, [pageComponents, pages]);

  return (
    <Suspense>
      <Router>
        <Routes>
          {routes}
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Workspace />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
