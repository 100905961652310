import * as React from 'react';
import { css } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import LoginForm from '../components/LoginForm';

const backgroundStyles = css({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'url("/background.jpg") no-repeat center center fixed',
  backgroundSize: 'cover',
});

const loginPaneStyles = css({
  backgroundColor: 'rgba(255, 255, 255, 0.98)',
  height: '100vh',
});

const Login = () => {
  return (
    <div css={backgroundStyles}>
      <Grid container display="flex" justifyContent="flex-end">
        <Grid
          item
          css={loginPaneStyles}
          xs={4}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
        >
          <Box p={2.5} pb={12}>
            <h4>Please login</h4>
            <LoginForm />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
