import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ServerStatus = {
  status: 'running' | 'stopped';
  sandbox: boolean;
  apiKey: string;
  apiSecret: string;
};

export const initialState: ServerStatus = {
  status: 'stopped',
  sandbox: false,
  apiKey: '',
  apiSecret: '',
};

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus: (
      state,
      action: PayloadAction<{
        status: 'stopped' | 'running';
        sandbox: boolean;
      }>
    ) => {
      state.status = action.payload.status;
      state.sandbox = action.payload.sandbox;
    },
    setApiDetails: (
      state,
      action: PayloadAction<{ apiKey: string; apiSecret: string }>
    ) => {
      state.apiKey = action.payload.apiKey;
      state.apiSecret = action.payload.apiSecret;
    },
  },
});

export const { setStatus, setApiDetails } = statusSlice.actions;
export default statusSlice.reducer;
