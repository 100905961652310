import React, { useState } from 'react';
import Navigator from './Navigator';
import { css } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import Profile from './Profile';
import Subscriptions from './Subscriptions';
import { Page } from '../types';

const pages = [
  { name: 'profile', title: 'Profile', component: <Profile /> },
  { name: 'subscriptions', title: 'Subscriptions', component: <Subscriptions /> },
];

const navigatorStyles = css({
  background: '#eee',
});

const Settings = () => {
  const [selectedPage, setSelectedPage] = useState<Page>(pages[0]);

  return (
    <Grid height="100%" minHeight={400} container p={0}>
      <Grid item xs={3} css={navigatorStyles}>
        <Navigator
          pages={pages}
          selectedPage={selectedPage}
          onPageSelection={(page) => {
            if (page) {
              setSelectedPage(page);
            }
          }}
        />
      </Grid>
      <Grid item xs={9} p={2} mt={2} overflow="auto">
        <Typography variant="h4">{selectedPage.title}</Typography>
        <Box mt={2}>{selectedPage.component}</Box>
      </Grid>
    </Grid>
  );
};

export default Settings;
