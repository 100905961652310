import React from 'react';
import { Avatar, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { css } from '@emotion/react';

const formStyle = css({
  'td,th': {
    paddingBottom: 12,
    verticalAlign: 'middle',
  },
  th: {
    textAlign: 'left',
    paddingRight: 16,
  },
});

const Profile = () => {
  return (
    <Grid container>
      <Grid xs={12} md={3}>
        <Avatar sx={{ width: 128, height: 128 }} />
      </Grid>
      <Grid xs={12} md={9} mt={1}>
        <table border={0} css={formStyle}>
          <tbody>
            <tr>
              <th>Name</th>
              <td>
                <TextField variant="outlined" fullWidth size="small" />
              </td>
            </tr>
            <tr>
              <th>Email</th>
              <td>
                <TextField variant="outlined" fullWidth size="small" />
              </td>
            </tr>
          </tbody>
        </table>
      </Grid>
    </Grid>
  );
};

export default Profile;
