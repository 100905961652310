import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MarketParticipation } from '../../types';
import { GridRowId } from '@mui/x-data-grid';

type ConfigState = {
  tradingParams: { key: string; type: string; value: number | string | boolean }[];
  marketParticipation: MarketParticipation[];
};

const initialState: ConfigState = {
  tradingParams: [],
  marketParticipation: [],
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setTradingParams: (
      state,
      action: PayloadAction<{ key: string; type: string; value: number | string | boolean }[]>
    ) => {
      state.tradingParams = action.payload;
    },
    setTradingParam: (
      state,
      action: PayloadAction<{ key: string; type: string; value: number | string | boolean }>
    ) => {
      const index = state.tradingParams.findIndex((p) => p.key === action.payload.key);
      state.tradingParams[index] = action.payload;
    },
    addMarket: (state, action: PayloadAction<MarketParticipation>) => {
      state.marketParticipation.push(action.payload);
    },
    setMarket: (state, action: PayloadAction<MarketParticipation>) => {
      const index = state.marketParticipation.findIndex((m) => m.id === action.payload.id);
      if (index > -1) {
        state.marketParticipation[index] = action.payload;
      }
    },
    deleteMarket: (state, action: PayloadAction<GridRowId>) => {
      const index = state.marketParticipation.findIndex((m) => m.id === action.payload);
      if (index > -1) {
        state.marketParticipation.splice(index, 1);
      }
    },
  },
});

export const { setTradingParam, setTradingParams, addMarket, setMarket, deleteMarket } = configSlice.actions;
export default configSlice.reducer;
