import axios from 'axios';

const baseUrl = '/api/v1';

export const login = async (username?: string, password?: string) => {
  const url = `/login`;
  const response = await axios.post(url, { username, password });
  const token = response.data.token;
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return response.data;
};

export const logout = async () => {
  const url = `${baseUrl}/logout`;
  await axios.post(url);
  delete axios.defaults.headers.common['Authorization'];
};
