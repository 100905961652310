import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import { css } from '@emotion/react';
import {
  getSubscriptionInfo,
  getSubscriptions,
  subscribeToTopic,
  unsubscribeFromTopic,
} from '../features/settings/api';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { addSubscription, deleteSubscription } from '../features/settings/settingsSlice';
import { Subscription } from '../types';

type SubscriptionDescription = {
  id: string;
  label: string;
};
const channels = [{ id: 'email', label: 'E-mail' }];

const handleSwitchToggle =
  (dispatch: AppDispatch, topic: string, channel: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    try {
      if (isChecked) {
        await subscribeToTopic(topic, channel);
        dispatch(addSubscription({ topic, channel }));
      } else {
        await unsubscribeFromTopic(topic, channel);
        dispatch(deleteSubscription({ topic, channel }));
      }
    } catch (error) {
      console.error('Failed to toggle subscription', error);
    }
  };

const isSubscribed = (subscriptions: Subscription[], topic: string, channel: string) => {
  return subscriptions.some((s) => s.topic === topic && s.channel === channel);
};

const Subscriptions = () => {
  const dispatch: AppDispatch = useDispatch();

  const [allSubscriptions, setAllSubscriptions] = useState<SubscriptionDescription[]>([]);
  const subscriptions = useSelector((state: RootState) => state.settings.subscriptions);

  useEffect(() => {
    if (allSubscriptions.length === 0) {
      getSubscriptionInfo().then(({ all }) => {
        setAllSubscriptions(all);
      });
    }
  }, []);

  useEffect(() => {
    getSubscriptions().then((subscriptions) => {
      subscriptions.forEach((subscription: Subscription) => {
        dispatch(addSubscription(subscription));
      });
    });
  }, [dispatch]);

  return (
    <Box>
      {allSubscriptions.map((subscription) => {
        return (
          <Box pb={1} key={subscription.id}>
            <Typography variant="h6">{subscription.label}</Typography>
            <Box pl={3}>
              <FormGroup css={css({ columns: 3, columnGap: 4 })}>
                {channels.map(({ id, label }) => (
                  <FormControlLabel
                    key={id}
                    control={
                      <Switch
                        value={isSubscribed(subscriptions, subscription.id, id)}
                        onChange={handleSwitchToggle(dispatch, subscription.id, id)}
                      />
                    }
                    label={label}
                  />
                ))}
              </FormGroup>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default Subscriptions;
