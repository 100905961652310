import { Contact, Subscription } from '../../types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SettingsSlice = {
  name: string;
  contacts: Contact[];
  subscriptions: Subscription[];
};

const initialState: SettingsSlice = {
  name: '',
  contacts: [],
  subscriptions: [],
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    addContact: (state, action: PayloadAction<Contact>) => {
      state.contacts.push(action.payload);
    },
    deleteContact: (state, action: PayloadAction<Contact>) => {
      const index = state.contacts.findIndex((contact) => contact.id === action.payload.id);
      if (index > -1) {
        state.contacts.splice(index, 1);
      }
    },
    addSubscription: (state, action: PayloadAction<Subscription>) => {
      state.subscriptions.push(action.payload);
    },
    deleteSubscription: (state, action: PayloadAction<Subscription>) => {
      const { topic, channel } = action.payload;
      const index = state.subscriptions.findIndex((s) => s.topic === topic && s.channel === channel);
      if (index > -1) {
        state.subscriptions.splice(index, 1);
      }
    },
  },
});

export const { setName, addContact, deleteContact, addSubscription, deleteSubscription } = settingsSlice.actions;
export default settingsSlice.reducer;
