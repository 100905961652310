import axios from 'axios';

const baseUrl = '/api/v1';

export const getSubscriptionInfo = async () => {
  const url = `${baseUrl}/subscriptions/info`;
  const response = await axios.get(url);
  return response.data;
};
export const getSubscriptions = async () => {
  const url = `${baseUrl}/subscriptions`;
  const response = await axios.get(url);
  return response.data;
};
export const subscribeToTopic = async (topic: string, channel: string) => {
  const url = `${baseUrl}/subscriptions`;
  try {
    const response = await axios.post(url, { topic, channel });
    return response.data;
  } catch (error) {
    console.error('Failed to subscribe to topic', error);
    throw error;
  }
};

export const unsubscribeFromTopic = async (topic: string, channel: string) => {
  const url = `${baseUrl}/subscriptions`;
  try {
    return axios.delete(url, { data: { topic, channel } });
  } catch (error) {
    console.error('Failed to unsubscribe from topic', error);
    throw error;
  }
};
